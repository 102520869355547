<template>
  <section class="bg_color">
    <h2>マイページTOP</h2>
    <div class="inner">
      <div class="box_user_news">
        <h3>NEWS<span>ニュース</span></h3>
        <ul class="lst_news">
          <li
            v-bind:class="diffDateShow(momentCal(val.date), dateToday)"
            v-for="val in infomation"
            :key="val"
          >
            <a
              href="#"
              class="txt-link news-link-my"
              v-on:click="openModal(val.body)"
              ><span class="date">{{ moment(val.date) }}</span
              ><br class="hidden-md" /><span class="news">{{
                val.title
              }}</span></a
            >
          </li>
        </ul>
        <transition name="fade" v-show="isShow" @close="closeModal">
          <div>
            <div class="modal_inner modal_inner--01">
              <div class="modal_box">
                {{ infobody }}
                <button class="btn_modal_close_right" @click="closeModal()">
                  <img
                    src="../assets/images/parts/ico_close.svg"
                    width=""
                    height=""
                    alt="閉じる"
                  />
                </button>
              </div>
            </div>
            <div class="modal_outer" @click="closeModal()"></div>
          </div>
        </transition>
      </div>

      <section>
        <div class="ribon_outline">
          <div class="ttl_ribon">
            <p class="ribon_inner"><Today /></p>
          </div>
        </div>
        <h3 class="ttl_main"><LastRace /></h3>
        <p class="tyu">※獲得モグ―の更新は、レース終了後約5分後に行われます。</p>
        <!--アンケート-->
        <div id="questionnaire" class="box_lst">
          <h4 class="ttl_lst mypage">
            <p class="inner">MOGアンケート</p>
          </h4>
          <div class="outer_mypage_questionnaire">
            <p v-if="info.answer != null">
              アンケートのご協力ありがとうございました。<br />貴重なご意見を基に、MOGの発展へ精進して参ります。
            </p>
            <div v-else>
              <p>
                いつもMOGのご利用ありがとうございます。<br />MOGについて皆様のご意見をお伺いしたく、アンケートのご協力よろしくお願いいたします。
              </p>
              <p>
                <textarea v-model="questionnaire" id="" rows="10"></textarea>
              </p>
              <p class="confirm">
                <button v-on:click.prevent="sendQuestionnair()">送信</button>
              </p>
            </div>
          </div>
        </div>
        <!--mog&rank-->
        <div id="mog_rank" class="box_lst">
          <h4 class="ttl_lst mypage">
            <figure class="ico_user">
              <img
                :src="info.icon ? info.icon : Icon[0].img"
                height=""
                alt=""
              />
            </figure>
            <p class="inner">
              <span class="name">{{ info.nickname }}</span
              >獲得モグー&amp;現在ランキング
            </p>
          </h4>
          <div class="outer_mypage_mogrank">
            <div class="inner_mypage_mogrank">
              <div class="box_mog">
                <div class="ribon_outline none">
                  <div class="ttl_ribon">
                    <p class="ribon_inner">獲得モグー</p>
                  </div>
                </div>
                <p class="point">
                  <span class="mog">{{ addComma(info.mog) }}</span>
                </p>
              </div>
              <div class="box_rank">
                <div class="ribon_outline none">
                  <div class="ttl_ribon">
                    <p class="ribon_inner">ランキング</p>
                  </div>
                </div>
                <p class="ranking">
                  <span class="rank">{{ info.rank }}</span
                  ><span class="total">{{ info.total_member_count }}</span
                  ><span class="small">人中</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--VS GAMA FAN-->
        <div id="vs_fan" class="box_lst">
          <h4 class="ttl_lst mypage">
            <p class="inner">
              <span class="sub_txt">GAMAファン編特別企画</span>VS GAMAファン
            </p>
          </h4>
          <div class="vs_fan_content">
            <div class="vs_select">
              <p class="txt01">参加者を選んで年間獲得MOGを競え！</p>
              <p class="txt02">
                勝利した人の中から抽選で選択した<br />GAMAファン編参加者のサイン入り<br
                  class="hidden-md"
                />QUOカードをプレゼント！
              </p>
              <div class="vs_list_box" v-if="info.match_user == null">
                <div class="vs_list_block" v-for="val in filterUser" :key="val">
                  <label>
                    <p class="pho01">
                      <img :src="val.icon ? val.icon : Icon[0].img" alt="" />
                    </p>
                    <p class="vs_name01">{{ val.nickname }}</p>
                    <input
                      type="radio"
                      v-model="vs_list"
                      :value="val.id"
                      id="vs01"
                    /><span class="radio_circle"></span>
                  </label>
                </div>
              </div>
              <p class="vs_submit01" v-if="info.match_user == null">
                <input
                  type="button"
                  value="選んだ参加者と対戦する"
                  v-on:click="registRoyalUser()"
                />
              </p>
            </div>
            <div class="vs_battle" v-if="info.match_user !== null">
              <p class="battle_title">
                あなたは{{ royalUser.nickname }}さんと対戦中です
              </p>
              <div class="battle_box">
                <div class="battle_block">
                  <p class="pho01">
                    <img
                      :src="info.icon ? info.icon : Icon[0].img"
                      height=""
                      alt=""
                    />
                  </p>
                  <p class="battle_name">{{ info.nickname }}</p>
                </div>
                <p class="vs_icon">
                  <img src="@/assets/images/mypage/icon_vs.svg" alt="" />
                </p>
                <div class="battle_block">
                  <p class="pho01">
                    <img
                      :src="royalUser.icon ? royalUser.icon : Icon[0].img"
                      alt=""
                    />
                  </p>
                  <p class="battle_name">{{ royalUser.nickname }}</p>
                </div>
              </div>
              <p class="vs_submit02">
                <router-link
                  v-if="info.match_user"
                  :to="{ name: 'NominateDtl', params: { id: info.match_user } }"
                  class="btn_dtl"
                >
                  対戦相手の詳細を見る
                </router-link>
              </p>
            </div>
            <p class="s-txt">
              ※GAMAファン編参加者1名あたり5名にプレゼント。<br />※一度対戦相手を選ぶと、変更はできません。
            </p>
          </div>
        </div>
        <!--royaluser comment-->
        <div
          id="royaluser"
          class="box_lst"
          v-if="info.user_type === '8' || info.user_type === '9'"
        >
          <h4 class="ttl_lst mypage">
            <figure class="ico_moter">
              <img
                src="../assets/images/ranking/ico_ranking_moter.svg"
                width=""
                height=""
                alt=""
              />
            </figure>
            <p class="inner">
              <span class="name">{{ info.nickname }}</span
              >一言コメント
            </p>
          </h4>
          <div class="outer_mypage_mogrank">
            <div class="inner_mypage_mogrank">
              <div class="box_mog">
                <textarea
                  name="royal_comment"
                  id=""
                  rows="10"
                  v-model="comment"
                ></textarea>
                <p class="submit">
                  <input
                    type="submit"
                    value="更&emsp;新"
                    v-on:click.prevent="regist()"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--moter-->
        <div id="entry" class="box_lst">
          <img
            src="../assets/images/mypage/mes_moter_entry.svg"
            width=""
            height=""
            alt="モーターは6基まで選択できます"
            class="message"
          />
          <h4 class="ttl_lst mypage">
            <figure class="ico_moter">
              <img
                src="../assets/images/ranking/ico_ranking_moter.svg"
                width=""
                height=""
                alt=""
              />
            </figure>
            <p class="inner">
              <span class="name">{{ info.nickname }}</span
              >登録したモーター
            </p>
          </h4>
          <div
            class="outer_mypage_moter"
            v-if="selectedMotor && selectedMotor.length"
          >
            <p class="inner90 FontS">
              ※登録後獲得モグ―とは、お客様がモーターを登録してから<br
                class="hidden-md"
              />獲得したモグ―となります。
            </p>
            <!-- <MyMotor /> -->
            <Splide :options="options">
              <SplideSlide v-for="val in selectedMotor" :key="val">
                <div class="box_moter_entry">
                  <div class="moter_dtl">
                    <dl>
                      <dt>モーター番号</dt>
                      <dd>{{ val.mno }}</dd>
                      <dt>登録後獲得モグー</dt>
                      <dd>
                        <span class="mog">{{ addComma(val.current_mog) }}</span>
                      </dd>
                      <dt>登録日</dt>
                      <dd>{{ momentCal(val.selected_date) }}</dd>
                      <dt>今節の使用者</dt>
                      <dd class="new">
                        {{ val.curret_racer_name }}({{ val.curret_racer_kyu }})
                      </dd>
                      <dt class="getmog">今節獲得モグー</dt>
                      <dd class="getmog">
                        <span class="mog">{{
                          addComma(val.current_section_mog)
                        }}</span>
                      </dd>
                    </dl>
                    <figure>
                      <img :src="motorImg[val.mno]" width="" height="" alt="" />
                    </figure>
                  </div>
                  <div class="box_lnk">
                    <router-link
                      :to="{ name: 'MotorDtl', params: { id: val.mno } }"
                      class="btn_dtl"
                      >モーター履歴を見る</router-link
                    >
                  </div>
                </div>
              </SplideSlide>
            </Splide>
            <p class="box_add_moter">
              <router-link to="/select_motor" class="btn_add">
                モーター一覧ページへ</router-link
              >
            </p>
          </div>
          <div class="no_select" v-else>
            <p>現在登録しているモーターはありません</p>
          </div>
        </div>
        <!--rankig graph-->
        <div id="rankig_graph" class="box_lst">
          <h4 class="ttl_lst mypage">
            <figure class="icon_graph">
              <img
                src="../assets/images/ranking/icon_graph.svg"
                width=""
                height=""
                alt=""
              />
            </figure>
            <p class="inner">
              <span class="name">{{ info.nickname }}</span
              >個人ランキング変動推移
            </p>
          </h4>
          <div class="graph_box">
            <chart
              v-if="loaded"
              :chartdata="chartdata"
              :options="chartoptions"
            ></chart>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import Mixin from "@/mixins/mixin";
import moment from "moment";
import LastRace from "@/components/LastRaceInfo.vue";
// import MyMotor from "@/components/SelectedMotor.vue";
import Today from "@/components/Today.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Chart from "./MyChart.vue";

export default {
  name: "mypageContent",
  data() {
    return {
      options: {
        rewind: true,
        width: 800,
        gap: "1rem",
      },
      vs_list: "",
      dateToday: "",
      isShow: false,
      infobody: "",
      comment: "",
      questionnaire: "",
      loaded: false,
      chartdata: {
        labels: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"],
        // labels: null,
        datasets: [
          {
            label: "ランキング推移",
            backgroundColor: "#f87979",
            borderColor: "#E24729",
            pointBackgroundColor: "#fff",
            data: [1, 2, 3, 4, 5],
            fill: false,
            tension: 0.1,
          },
        ],
      },
      chartoptions: {
        title: {
          display: true,
          text: "ランキング遷移",
        },
        scales: {
          xAxes: [
            {
              // X軸
              gridLines: {
                color: "rgba(255, 0, 0, 0.2)",
                zeroLineColor: "black",
              },
              ticks: {
                Max: 35,
                Min: 1,
                stepSize: 1, // ステップサイズを設定
              },
            },
          ],
          yAxes: [
            {
              // Y軸
              gridLines: {
                backdropColor: "#231815",
                color: "rgba(0, 0, 255, 0.2)",
                zeroLineColor: "black",
              },
              ticks: {
                reverse: true,
                Max: 11,
                Min: 1,
                stepSize: 10,
                position: () =>
                  this.chartdata.datasets &&
                  this.chartdata.datasets[0].data === 0
                    ? "bottom"
                    : "left",
                callback: (value) => {
                  let label = "";
                  if (value === 0) {
                    label = "圏外";
                  } else if (value <= this.info.total_member_count) {
                    label = value + "位";
                  } else {
                    label = "";
                  }
                  return label;
                },
              },
            },
          ],
        },
      },
    };
  },
  components: {
    LastRace,
    Today,
    // MyMotor,
    Splide,
    SplideSlide,
    Chart,
  },
  beforeMount: async function () {
    await this.$store.dispatch("memberInfo");
    if (this.info.match_user !== null) {
      await this.$store.dispatch("getRoyalUserDispatch", this.info.match_user);
    }
    await this.$store.dispatch("getRoyalUserList");
    await this.$store.dispatch("getInformation");
    await this.$store.dispatch("reqSelectedMotor");
    await this.$store.dispatch("getOwnerRankingChart", this.info.member_id);

    this.comment = this.info.comment;

    let labels = Array();
    let mydata = Array();
    let chartArray = Object.values(this.myChart);
    let myChart = chartArray.values();
    for (const val of myChart) {
      for (const v of val) {
        labels.push(Math.trunc(v.section) + "節");
        mydata.push(v.rank);
      }
    }
    this.chartdata.labels = labels;
    this.chartdata.datasets[0].data = mydata;
    this.loaded = true;
  },
  mounted: async function () {
    await this.$setInterval(() => {
      this.$store.dispatch("getInformation");
      this.$store.dispatch("memberInfo");
      this.$store.dispatch("getOwnerRankingChart", this.info.member_id);
    }, 15000);
    this.dateToday = moment(new Date()).format("YYYY/MM/DD");
  },
  computed: {
    ...mapGetters({
      infomation: "getInformation",
      info: "getMemberInfo",
      Icon: "getIcon",
      selectedMotor: "getSeletedMotor",
      motorImg: "getMotorImg",
      myChart: "getOwnerChart",
      user: "getRoyalUserList",
      royalUser: "getRoyalUser",
    }),
    filterUser() {
      if (Array.isArray(this.user)) {
        return this.user.filter((val) => val.user_type === "9");
      } else {
        return [];
      }
    },
  },
  mixins: [Mixin],
  methods: {
    openModal: function (val) {
      this.isShow = true;
      this.infobody = val;
    },
    closeModal() {
      this.isShow = false;
      this.infobody = "";
    },
    moment: function (date) {
      return moment(date).format("YYYY年MM月DD日");
    },
    momentCal: function (date) {
      return moment(date).format("YYYY/MM/DD");
    },
    diffDate: function (news_date, today) {
      return moment(news_date).diff(today, "days");
    },
    diffDateShow: function (news_date, today) {
      if (moment(news_date).diff(today, "days") > -10) {
        return "new";
      }
      return "";
    },
    regist: function () {
      this.$store.dispatch("registComment", this.comment).then((response) => {
        if (response.data.result) {
          alert("コメントを投稿しました");
        } else {
          alert("投稿に失敗しました。");
        }
      });
    },
    sendQuestionnair: function () {
      this.$store
        .dispatch("sendQuestionnaire", this.questionnaire)
        .then((response) => {
          if (response.data.result) {
            alert("アンケートのご協力ありがとうございました");
            this.$store.dispatch("memberInfo");
          } else {
            alert("投稿に失敗しました");
          }
        });
    },
    registRoyalUser: function () {
      this.$store.dispatch("registRoyalUser", this.vs_list).then((response) => {
        if (response.data.result) {
          alert("対戦者の登録が完了しました。");
          this.$store.dispatch("memberInfo");
        } else {
          alert("投稿に失敗しました");
        }
      });
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
<style scoped>
.no_select p {
  padding: 3%;
  text-align: center;
}
</style>
